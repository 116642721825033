import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
import { MessagesService } from '../services/messages.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../services/utils.service';
import { Subscription } from 'rxjs';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SocketioService } from '../services/socketio.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.styl', './contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contacts: any
  @Input() updateCounter: any
  @Input() selectedChat: any
  @Input() updateContacts: any
  @Input() availableSelectChat: any
  @Output() selectedChatEmit = new EventEmitter()
  @Output() sendedBroadcast = new EventEmitter()
  user = JSON.parse(localStorage.getItem('user'))
  faSearch = faSearch
  allChats: any
  textBroadcastMessage: String = ''
  searchingText: String = ''
  modalOptions:NgbModalOptions;
  private subscription: Subscription = new Subscription()

  warningContacts: any;
  faExclamationTriangle = faExclamationTriangle;
  chatsUpdateSender: any;
  textSearch: String = ''
  onlineUsers: any = [];

  constructor(private messagesApi: MessagesService, private modalService: NgbModal, private utils: UtilsService) {
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop'
    }
  }

  async ngOnInit(): Promise<void> {
     
    this.allChats   = this.contacts
    let textSearch = localStorage.getItem("textSearch");

    if(textSearch != null &&
       textSearch != undefined &&
       typeof textSearch === 'string' &&
       textSearch != 'null' &&
       textSearch.length > 0) {
        
      this.searchingText = textSearch;
      this.filterContact(null);
    } else {
      this.searchingText = '';
      this.filterContact(null);
    }
    
    //there aren't contacts in list
    if (this.allChats && this.allChats.length == 0) {
      this.warningContacts = true
    } else {
      this.warningContacts = false
    }


    this.availableSelectChat = true;

    let singleUser = await this.verifySingleUser();
    console.log(singleUser)
    if (!singleUser) {
      await this.initializeWithSelectedContact();
    }
    
    await this.getOnlineUsers();

    this.subscription.add(
      SocketioService.socketContactStateChanged.subscribe(data => {
        console.log(`socket de contato conectado ou desconectado`);
        this.getOnlineUsers();
      })
    )
  }

  async getOnlineUsers() {
    
    try {
      
      this.messagesApi.getOnlineUsers().subscribe(async (result: any) => {
        this.onlineUsers = result.result;

        console.log(`online users? ${this.onlineUsers}`);
      })

    } catch (error) {

      console.log(error);

    }
  }
    
  async ngOnChanges(changes: SimpleChanges): Promise<void> {

    console.log(changes);

    for (let attr in changes) {
      if (attr == 'contacts' && changes.contacts.currentValue) {
        this.contacts = this.orderChats(changes.contacts.currentValue)
        
      }

      if (attr == 'selectedChat') {
        if(localStorage.getItem('scrollToContact') == this.selectedChat.userId) {
          document.getElementById(`contact-${this.selectedChat.userId}`).scrollIntoView();
          localStorage.removeItem('scrollToContact');
        }
      //   if (changes.selectedChat.currentValue) {
      //     this.selectedChat.selected = true
          
      //     this.messagesApi.isUserOnline(this.selectedChat.userId).subscribe(async (result: any) => {
        
      //       if (result.online) {
              
      //       }
    
      //     });
      //   }
      }

      if (attr == 'updateContacts') {
        if (changes.updateContacts.currentValue) {
          let updatedChat = this.contacts.find(chat => chat.chatId == changes.updateContacts.currentValue.chat)
          updatedChat.lastMessage = changes.updateContacts.currentValue
          updatedChat.lastMessage.createdAt = changes.updateContacts.currentValue.sentAt
          this.contacts = this.orderChats(this.contacts)
        }
      }
    }

    
  }

  //Comunicação com abas do Manager
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    console.log(`onMessage: ${JSON.stringify(event.data)}`);
    if(typeof event.data == 'object') {
      if(event.data.type == 'openChat') {
        if(event.data.params) {
          var cpf = event.data.params.cpf || event.data.params.chatCpf;
          if(cpf) {
            this.toSelectChat(this.findChatByCpf(cpf));
            var resourceId = event.data.params.resource_id || event.data.params.resourceId;
            if(resourceId) {
              localStorage.setItem('OpenChatResource', resourceId);
            }
          }
        }
      }
    }
  }

  async verifySingleUser() {

    let verify = false;
    let singleUser = localStorage.getItem('@OMNIHUNTERsingleUser');
    let chatCpf = localStorage.getItem('chatCpf');

    if (singleUser && chatCpf) {
      this.allChats.find(chat => { 
        if (chat.userCpf == chatCpf) {
          verify = true;
          this.toSelectChat(chat);
          localStorage.setItem('scrollToContact', chat.userId);
        }
      })

      if(verify) {
        console.log(`verifySingleUser: ${chatCpf} encontrado`);
      } else {
        console.log(`verifySingleUser: ${chatCpf} não encontrado`);
      }
    } else {
      console.log(`verifySingleUser: (singleUser && chatCpf) == false`);
    }

    //localStorage.removeItem('@OMNIHUNTERsingleUser');
    localStorage.removeItem('chatCpf');

    return verify;

  }

  async initializeWithSelectedContact() {

    if (localStorage.getItem('chatCpf')) {
      let chatCpf = localStorage.getItem('chatCpf');

      console.log(chatCpf);

      console.log(this.allChats);
      
      let chatCpfSelected = this.allChats.find(chat => { 
        if (chat.userCpf == chatCpf) {
          return chat;
        }
      })
      
      if(chatCpfSelected) {
        console.log(`initializeWithSelectedContact: ${chatCpf}`);
        this.toSelectChat(chatCpfSelected);
        // this.selectedVehicle = vehicle;
        // this.selectedVehicleEmit.emit(vehicle)
        // vehicle.selected = true

        localStorage.removeItem('chatCpf');
        localStorage.setItem('chatCpfOpen', 'true');
      } else {
        console.log(`initializeWithSelectedContact: chat não encontrado`);
      }
    }
  
  }

  truncate(string: string, charNumber: number, charIfNull: string){
    if (string === null || string  === undefined)
      return charIfNull
    if (string.length < charNumber)
      return string
    else
      return string.slice(0, charNumber - 3) + '...'
  }

  filterContact($event) {
    if (this.searchingText != '') {
      this.allChats = this.contacts.filter(chat => this.filterByName(chat, this.searchingText))
    } else {
      this.allChats = this.contacts
    }
  }

  filterByName(chat, text) {
    if(!chat.name) {
      return false;
    }
    
    let name = this.normalizeText(chat.name)
    
    if (name.includes(this.normalizeText(text))) {
      return true;
    }
  }

  normalizeText = (text) => {
    var r = text.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'), "");
    r = r.replace(new RegExp("[àáâãäå]", 'g'), "a");
    r = r.replace(new RegExp("æ", 'g'), "ae");
    r = r.replace(new RegExp("ç", 'g'), "c");
    r = r.replace(new RegExp("[èéêë]", 'g'), "e");
    r = r.replace(new RegExp("[ìíîï]", 'g'), "i");
    r = r.replace(new RegExp("ñ", 'g'), "n");
    r = r.replace(new RegExp("[òóôõö]", 'g'), "o");
    r = r.replace(new RegExp("œ", 'g'), "oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'), "u");
    r = r.replace(new RegExp("[ýÿ]", 'g'), "y");
    r = r.replace(new RegExp("\\W", 'g'), "");
    return r;
  }

  orderChats(chats) {
    return chats.sort(function (a, b) {

      if (a.lastMessage && b.lastMessage) {
        // Se ambos têm lastMessage, compara os dois
        return new Date(a.lastMessage.createdAt) > new Date(b.lastMessage.createdAt) ? -1 : 1;
      } else if (a.lastMessage) {
          // Se apenas a tem lastMessage, a fica antes
          return -1;
      } else if (b.lastMessage) {
          // Se apenas b tem lastMessage, b fica antes
          return 1;
      } else {
          // Se nenhum tem lastMessage, compara os created_at
          return new Date(a.created_at) > new Date(b.created_at) ? -1 : 1;
      }
    })
  }

  getLastDate(chat) {
    return chat && chat.lastMessage ? chat.lastMessage.createdAt : chat.createdAt
  }

  toSelectChat(currentChat) {

    if(this.availableSelectChat) {
      
      this.contacts.forEach(chat => {
        if (chat.chatId == currentChat.chatId) {
          this.selectedChatEmit.emit(chat)
          chat.selected = true
          this.toVisualizeChatMessages(chat)

          this.utils.scrollToMessages();
        
        } else {
          chat.selected = false
        }
      });

    }
  
  }

  findChatByCpf(cpf) {
    var chat = null;
    this.contacts.forEach(contact => {
      if(contact.userCpf == cpf) {
        chat = contact;
      }
    });

    return chat;
  }

  selectSuporteOMNIHUNTER() {
    var chat = this.findChatByCpf("33333333333");
    if(chat) {
      this.toSelectChat(chat);

      document.getElementById(`contact-${chat.userId}`).scrollIntoView();
    } else {
      alert("Não foi possível abrir o contato do suporte");
    }
  }

  toVisualizeChatMessages(chat){
    if (this.user.role != "master") {
      this.subscription.add(
        this.messagesApi.visualizeChatMessages(chat.chatId).subscribe(result => {
          console.log(result);
          chat.notVisualizedCount = 0
        })
      )
    }
  }

  getMessageType(type) {
    switch (type) {
      case 'diligence':
        return 'Diligência'
      default:
        return ''
    }
  }

  toSendBroadcastMessage(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      if (this.textBroadcastMessage.length < 1 && this.textBroadcastMessage.trim() != '') {
        return alert('Não foi possível enviar uma mensagem vazia. Tente novamente.')
      }

      let user = JSON.parse(localStorage.getItem('user'))
      var message = {
          from: user._id,
          message: this.textBroadcastMessage,
          type: 'text',
          coordinates: ''
      }
      
      this.subscription.add(
        this.messagesApi.sendBroadcastMessage(message).subscribe((result: any) => {
          this.textBroadcastMessage = ''
          alert('A mensagem está sendo enviada para todos os seus contatos no Chat, em breve o envio será concluído.')
          //this.sendedBroadcast.emit(true)
        })
      )
    }, (reason) => {
      console.log(reason);
    });
  }
  
  largeScreen() {
    return this.utils.largeScreen()
  }

  getUrlProfile(chat){
    let urls = this.utils.getProfileUrl()
    return chat.role== 'operator' || this.user.role == "master" ? urls.operatorImageUrl : urls.locatorImageUrl
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    this.subscription.unsubscribe()
  }
}
